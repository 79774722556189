const workerMobilePrefix = "Worker Mobile:";

export const USER_EVENTS = {
  ADD_LICENSE_TAPPED: `${workerMobilePrefix} Add License Tapped`,
  API_FAILURE: `${workerMobilePrefix} API Failed`,
  APP_LAUNCHED: `${workerMobilePrefix} App Launched`,
  APP_RESTORED: `${workerMobilePrefix} App Restored`,
  AUTO_WITHDRAW_CONFIRMED: `${workerMobilePrefix} Auto Withdraw Confirmed`,
  AUTO_WITHDRAW_DISABLED: `${workerMobilePrefix} Auto Withdraw Disabled`,
  AUTO_WITHDRAW_REJECTED: `${workerMobilePrefix} Auto Withdraw Rejected`,
  AUTO_WITHDRAW_REQUESTED: `${workerMobilePrefix} Auto Withdraw Requested`,
  BOOK_INSTANTBOOK_SHIFT_DISCREPANCY: `${workerMobilePrefix} Shift Booked But With Mismatch Pay`,
  BOOK_INSTANTBOOK_SHIFT: `${workerMobilePrefix} Shift Booked`,
  BOOK_WITH_A_FRIEND: `${workerMobilePrefix} Book with a friend`,
  BOOKED_A_SHIFT: `${workerMobilePrefix} Shift Booked`,
  CHAT_STARTED: `${workerMobilePrefix} Chat Started`,
  CHOSE_TIMESHEET_PHOTO: `${workerMobilePrefix} Timesheet Photo Chosen`,
  CLOCK_IN: `${workerMobilePrefix} Clocked In`,
  CLOCK_OUT: `${workerMobilePrefix} Clocked Out`,
  TAPPED_CLOCK_OUT_EARLY: `${workerMobilePrefix} Tapped Clocked Out`,
  CLOCKED_OUT_EARLY: `${workerMobilePrefix} Clocked Out Early`,
  CANCELLED_CLOCKED_OUT_EARLY: `${workerMobilePrefix} Cancelled Clock Out Early`,
  START_BREAK: `${workerMobilePrefix} Break Started`,
  END_BREAK: `${workerMobilePrefix} Break Ended`,
  TAPPED_SKIP_BREAK: `${workerMobilePrefix} Tapped Skip Break`,
  SKIP_BREAK: `${workerMobilePrefix} Break Skipped`,
  SKIP_BREAK_CANCEL: `${workerMobilePrefix} Break Skip Cancelled`,
  TAPPED_START_BREAK_EARLY: `${workerMobilePrefix} Tapped Start Break Early`,
  STARTED_BREAK_EARLY: `${workerMobilePrefix} Started Break Early`,
  CANCELLED_START_BREAK_EARLY: `${workerMobilePrefix} Cancelled Start Break Early`,
  TAPPED_END_BREAK_EARLY: `${workerMobilePrefix} Tapped End Break Early`,
  BREAK_END_EARLY: `${workerMobilePrefix} Break Ended Early`,
  BREAK_END_EARLY_CANCEL: `${workerMobilePrefix} Break End Early Canceled`,
  EDIT_CLOCK_IN__ERROR: `${workerMobilePrefix} Editable Clock In Error`,
  EDIT_CLOCK_OUT_ERROR: `${workerMobilePrefix} Editable Clock Out Error`,
  EDIT_END_BREAK_ERROR: `${workerMobilePrefix} Editable End Break Error`,
  EDIT_START_BREAK_ERROR: `${workerMobilePrefix} Editable Start Break Error`,
  NFC_SCAN_START: `${workerMobilePrefix} NFC Scan Start`,
  NFC_SCAN_CANCELLED: `${workerMobilePrefix} NFC Scan Cancelled`,
  NFC_SCAN_FAILED: `${workerMobilePrefix} NFC Scan Failed`,
  NFC_SCAN_SUCCEEDED: `${workerMobilePrefix} NFC Scan Succeeded`,
  COMPLETED_STRIPE_FLOW: `${workerMobilePrefix} Stripe Flow Completed`,
  COMPRESS_TIMESHEET_PHOTO_FAILED: `${workerMobilePrefix} Compress Timesheet Photo Failed`,
  CONTACT_STRIPE_CLICKED: `${workerMobilePrefix} Contact Stripe Clicked`,
  INVALID_DEEP_LINK: `${workerMobilePrefix} Invalid Deep Link`,
  DEEP_LINK_ERROR: `${workerMobilePrefix} Deep Link Error`,
  DEEP_LINK_OPENED: `${workerMobilePrefix} Deep Link Opened`,
  DISCOVERED_AN_INSTANT_BOOK_SHIFT: `${workerMobilePrefix} Instant Book Shift Discovered`,
  DISMISSED_STRIPE_PROMPT: `${workerMobilePrefix} Stripe Prompt Dismissed`,
  DISTANCE_REDIRECT: `${workerMobilePrefix} Distance Redirect Tapped`,
  DOCUMENT_DETAILS_SCREEN_VIEWED: `${workerMobilePrefix} Document Details Screen Viewed`,
  DOCUMENT_UPLOAD_FAILED: `${workerMobilePrefix} Document Upload Failed`,
  EDIT_TIMESHEET_SAVE_ERROR: `${workerMobilePrefix} Editable Timesheet Save Error`,
  EMAIL_PAYSTUB: `${workerMobilePrefix} Paystub Emailed`,
  ENTERED_SIGN_IN_CREDENTIAL: `${workerMobilePrefix} Sign In Credential Entered`,
  ENTERED_SIGN_UP_CREDENTIAL: `${workerMobilePrefix} Sign Up Credential Entered`,
  ENTERED_SSN: `${workerMobilePrefix} Onboarding - SSN Entered`,
  ENTERED_TWO_FACTOR_CODE: `${workerMobilePrefix} Two-Factor Code Entered`,
  FACILITY_CANCELLED_ME_REQUEST_SUBMITTED: `${workerMobilePrefix} Facility Cancelled Me Request Submitted`,
  FACILITY_REVIEW_FILTERED: `${workerMobilePrefix} Facility Review Filtered`,
  FIND_SHIFTS_HERE_BUTTON_CLICKED: `${workerMobilePrefix} - Find shifts here button clicked`,
  FRIEND_LINK_DOES_NOT_EXIST: `${workerMobilePrefix} Friend link does not exist`,
  FRIEND_REMOVED: `${workerMobilePrefix} Friend Removed`,
  FRIEND_REQUEST_ACCEPTED: `${workerMobilePrefix} Friend Request Accepted`,
  FRIEND_REQUEST_DECLINED: `${workerMobilePrefix} Friend Request Declined`,
  FRIEND_REQUEST_SENT: `${workerMobilePrefix} Friend request sent`,
  FRIENDS_LIST_VIEWED: `${workerMobilePrefix} Friend list viewed`,
  GENERATE_PROOF_INCOME: `${workerMobilePrefix} Proof Of Income Generated`,
  GET_CURRENT_POSITION_FAILED: `${workerMobilePrefix} Get Current Position Failed`,
  INSTANT_PAYOUT_CONFIRMED: `${workerMobilePrefix} Instant Payout Confirmed`,
  INSTANT_PAYOUT_REJECTED: `${workerMobilePrefix} Instant Payout Rejected`,
  INSTANT_PAYOUT_REQUESTED: `${workerMobilePrefix} Instant Payout Requested`,
  LICENSE_INFO_ENTERED: `${workerMobilePrefix} Onboarding - License Info Entered`,
  LICENSE_REMOVED: `${workerMobilePrefix} License Removed`,
  LICENSE_SUBMITTED: `${workerMobilePrefix} License Submitted`,
  LICENSE_VIEWED: `${workerMobilePrefix} License Viewed`,
  LICENSES_VIEWED: `${workerMobilePrefix} Licenses Viewed`,
  LINK_DOES_NOT_EXIST: `${workerMobilePrefix} Link does not exist`,
  LOCATION_TRACKING_STOPPED: `${workerMobilePrefix} Location Tracking Stopped`,
  LOGGED_OUT: `${workerMobilePrefix} Logged Out`,
  MANDATORY_BREAK_POLICY_VIEWED: `${workerMobilePrefix} Mandatory break policy modal viewed`,
  MANDATORY_BREAK_POLICY_CANCELLED: `${workerMobilePrefix} Mandatory break policy modal cancelled`,
  MANDATORY_BREAK_POLICY_ACCEPTED: `${workerMobilePrefix} Mandatory break policy modal accepted`,
  MARKED_INTEREST_IN_SHIFT: `${workerMobilePrefix} Marked Interest in Shift`,
  MESSAGE_DELIVERED: `${workerMobilePrefix} Message Delivered`,
  MESSAGE_OPENED: `${workerMobilePrefix} Message Opened`,
  MISSED_INSTANTPAY_CLOCK_IN: `${workerMobilePrefix} InstantPay Clock In Missed`,
  MISSING_REQUIREMENTS_ALERT_SHOWN: `${workerMobilePrefix} Missing Requirements Alert Shown`,
  MOVED_MAP: `${workerMobilePrefix} Map Moved`,
  NEED_HELP_PROOF_INCOME: `${workerMobilePrefix} Need Help on Proof Of Income Tapped`,
  NEGOTIABLE_MODAL_VIEWED: `${workerMobilePrefix} Negotiation Modal Viewed`,
  NEGOTIABLE_SHIFT_FOUND: `${workerMobilePrefix} Negotiable Shift Found`,
  NEGOTIATION_ENDED: `${workerMobilePrefix}  Negotiation Ended`,
  NEGOTIATION_RATE_PROPOSED: `${workerMobilePrefix} Rate Proposed`,
  NEGOTIATION_TIME_PROPOSED: `${workerMobilePrefix} Time Proposed`,
  NO_SHIFT_ALERT: `${workerMobilePrefix} No Shift Alert Triggered`,
  NOTIFICATION_CENTER_ICON_VIEWED: `${workerMobilePrefix} Notification Center Icon Viewed`,
  NOTIFICATION_CENTER_VIEWED: `${workerMobilePrefix} Notification Center Viewed`,
  ONBOARDING_ERROR: `${workerMobilePrefix} Self-Onboarding Error Occurred`,
  ONBOARDING_WORKER_PROFILE_FETCHING_ERROR: `${workerMobilePrefix} Fetching worker profile error dialogue viewed`,
  OPEN_DISTANCE_PREFERENCE_MODAL: `${workerMobilePrefix} Distance Preference Edited`,
  OPEN_SHIFT_COLLEAGUES_VIEWED: `${workerMobilePrefix} Open shift colleagues viewed`,
  OPEN_SHIFT_VIEWED: `${workerMobilePrefix} Open Shift Viewed`,
  OPENED_ETA_MODAL: `${workerMobilePrefix} ETA Modal Opened`,
  OPENED_FRIEND_LINK_LOGGED_OUT: `${workerMobilePrefix} Opened friend link logged out`,
  OPENED_HELP_CENTER: `${workerMobilePrefix} Open Help Center`,
  OPENED_OWN_FRIEND_LINK: `${workerMobilePrefix} Opened own friend link`,
  OPENED_OWN_LINK: `${workerMobilePrefix} Opened own link`,
  OPENED_TERMS_OF_SERVICE: `${workerMobilePrefix} Open Terms of Service`,
  OTA_UPDATE_DOWNLOAD_STARTED: `${workerMobilePrefix} - OTA Update - download started`,
  OTA_UPDATE_DOWNLOADED: `${workerMobilePrefix} - OTA Update - downloaded`,
  PAYMENT_HELP_FORM_OPENED: `${workerMobilePrefix} Payment Help Form Opened`,
  PAYMENT_HELP_FORMS_VIEWED: `${workerMobilePrefix} Payment Help Forms Viewed`,
  PRIORITY_ACCESS_PAGE_ARTICLE_CLICKED: `${workerMobilePrefix} Priority Access Page Article Clicked`,
  PRIORITY_ACCESS_PAGE_VIEW_FACILITIES_CLICKED: `${workerMobilePrefix} Priority Access Page View Facilities Clicked`,
  PROFILE_CHANGE_COMPLETED: `${workerMobilePrefix} Profile Updated`,
  PROFILE_CHANGE_STARTED: `${workerMobilePrefix} Profile Edited`,
  PUSH_NOTIFICATION_RECEIVED: `${workerMobilePrefix} Push Notification Received`,
  PUSH_NOTIFICATION_TAPPED: `${workerMobilePrefix} Push Notification Tapped`,
  QUALIFICATION_PREFERENCE_EDITED: `${workerMobilePrefix} Qualification Edited`,
  QUALIFICATION_PREFERENCE_UPDATED: `${workerMobilePrefix} Qualification Updated`,
  RADAR_API: `${workerMobilePrefix} Radar API called`,
  RATINGS_PAGE_VIEW_DETAILS_CLICKED: `${workerMobilePrefix} Ratings Page View Details Clicked`,
  READY_TO_EARN_PAGE_REFERRAL_CODE_SHARED: `${workerMobilePrefix} Ready to Earn Page Referral Code Shared`,
  READY_TO_EARN_PAGE_VIEWED: `${workerMobilePrefix} Ready to Earn Page Viewed`,
  REFER_A_FRIEND_PAGE_REFERRAL_CODE_SHARED: `${workerMobilePrefix} Refer a Friend Page Referral Code Shared`,
  REFERENCE_APP_SHARE_ICON_TAPPED: `${workerMobilePrefix} Reference App Share Icon Tapped`,
  REFERRAL_LINK_ERROR: `${workerMobilePrefix} Referral Link Error Occurred`,
  REFERRAL_SUBMISSION_PAGE_VIEWED: `${workerMobilePrefix} Enter referral code onboarding screen viewed`,
  REFERRAL_SUBMIT_BUTTON_CLICKED: `${workerMobilePrefix} Sign up referral code button clicked`,
  REQUEST_REFERENCE_BUTTON_TAPPED: `${workerMobilePrefix} Request References Button Tapped`,
  REQUESTED_SIGNATURE_FROM_HCF: `${workerMobilePrefix} HCF Signature Requested`,
  REVIEW_AND_SIGN_CANCEL: `${workerMobilePrefix} IC Agreement Cancelled`,
  SHARE_FRIEND_LINK: `${workerMobilePrefix} Share friend link`,
  SHARE_LINK_TO_THIS_SHIFT: `${workerMobilePrefix} Share link to this shift`,
  SHIFT_NOT_ATTENDED: `${workerMobilePrefix} Shift Not Attended`,
  SHIFT_OPENED: `${workerMobilePrefix} Shift Opened`,
  SHIFT_SIGNED_OFF: `${workerMobilePrefix} Shift Signed Off`,
  SHIFT_WRONG_DATE_SLOT: `${workerMobilePrefix} Shift Listed in Wrong Date Slot`,
  SIGNED_CONTRACT: `${workerMobilePrefix} Onboarding - Contract Signed`,
  SIGNED_IN: `${workerMobilePrefix} Signed In`,
  SIGNED_UP: `${workerMobilePrefix} Signed Up`,
  STARTED_CHAT: `${workerMobilePrefix} Start a Chat`,
  STARTED_STRIPE_SET_UP: `${workerMobilePrefix} Stripe Set Up Started`,
  STRIPE_IDENTITY_FLOW_BEGUN: `${workerMobilePrefix} Stripe Identity Flow Begun`,
  STRIPE_IDENTITY_FLOW_COMPLETED: `${workerMobilePrefix} Stripe Identity Flow Completed`,
  SUBMITTED_ETA_MODAL: `${workerMobilePrefix} ETA Modal Submitted`,
  SUBMITTED_FACILITY_REVIEW: `${workerMobilePrefix} Facility Review Submitted`,
  SUBMITTED_INSTANTPAY_TIMECARD_PHOTO: `${workerMobilePrefix} Instant Timesheet Uploaded`,
  SUCCESSFULLY_BOOKED_URGENT_SHIFT: `${workerMobilePrefix} Urgent Shift Booked`,
  SUCCESSFULLY_INSTANT_BOOKED_A_SHIFT: `${workerMobilePrefix} Shift Booked`,
  SUCCESSFULLY_UPLOADED_DOCUMENT: `${workerMobilePrefix} Document Upload Succeeded`,
  TAP_FACILITY_PIN: `${workerMobilePrefix} Map Facility Pin Tapped`,
  TAPPED_APPLE_MAPS: `${workerMobilePrefix} Open Apple Maps Tapped`,
  TAPPED_BOOK_SHIFT: `${workerMobilePrefix} Book Shift Tapped`,
  TAPPED_CALENDAR_DATE: `${workerMobilePrefix} Calendar Date Tapped`,
  TAPPED_CANCEL_MISSING_DOCS_POPUP_BUTTON: `${workerMobilePrefix} Missing Docs Alert - Cancel Tapped`,
  TAPPED_COMPLETE_SHIFT: `${workerMobilePrefix} Shift Completed`,
  TAPPED_GET_SHIFT_SIGNED_OFF: `${workerMobilePrefix} Get Shift Signed Off Tapped`,
  TAPPED_GO_TO_OPEN_SHIFTS: `${workerMobilePrefix} Go to Open Shifts Tapped`,
  TAPPED_GOOGLE_MAPS: `${workerMobilePrefix} Open Google Maps Tapped`,
  TAPPED_HELP_CALL: `${workerMobilePrefix} Help - Call Tapped`,
  TAPPED_HELP_EMAIL: `${workerMobilePrefix} Help - Email Tapped`,
  TAPPED_INSTANT_BOOK: `${workerMobilePrefix} Book Shift Tapped`,
  TAPPED_INSTANTBOOK_SHIFT: `${workerMobilePrefix} Book Shift Tapped`,
  TAPPED_LEARN_MORE: `${workerMobilePrefix} Learn More Tapped`,
  TAPPED_LINK_TO_REFER_A_CO_WORKER: `${workerMobilePrefix} Co-Worker Referred`,
  TAPPED_LOGOUT: `${workerMobilePrefix} Logout Tapped`,
  TAPPED_ONGOING_SHIFT: `${workerMobilePrefix} Ongoing Shift Tapped`,
  TAPPED_OPEN_MAPS: `${workerMobilePrefix} Open In Maps Tapped`,
  TAPPED_REFRESH: `${workerMobilePrefix} Refresh Tapped`,
  TAPPED_SHARE_REFERRAL_CODE: `${workerMobilePrefix} Referral code shared`,
  TAPPED_SIGN_IN: `${workerMobilePrefix} Sign In Tapped`,
  TAPPED_SIGN_UP: `${workerMobilePrefix} Sign Up Tapped`,
  TAPPED_ACCOUNT_MENU_ITEM: `${workerMobilePrefix} Account Menu Item Tapped`,
  TAPPED_TO_UPLOAD_INSTANTPAY_TIMECARD_PHOTO: `${workerMobilePrefix} Upload Timecard Photo Tapped`,
  TAPPED_TO_UPLOAD_TIMECARD_PHOTO: `${workerMobilePrefix} Upload Timecard Photo Tapped`,
  TAPPED_UPCOMING_SHIFT: `${workerMobilePrefix} Upcoming Shift Tapped`,
  TAPPED_UPLOAD_MISSING_DOCS_POPUP_BUTTON: `${workerMobilePrefix} Missing Docs Alert - Upload Docs Tapped`,
  TAPPED_UPLOAD_REQUIRED_DOCS: `${workerMobilePrefix} Missing Docs Alert - Upload Required Docs Tapped`,
  TAPPED_URGENT_SHIFTS_BANNER: `${workerMobilePrefix} Urgent Shifts Banner Tapped`,
  TIMECARD_SENT_ANOTHER_WAY: `${workerMobilePrefix} Timecard Sent Another Way`,
  TIMESHEET_PHOTO_FORGOTTEN: `${workerMobilePrefix} Timesheet Photo Forgotten`,
  TOGGLE_HIDE_AS_COLLEAGUE_ON_SHIFTS: `${workerMobilePrefix} Toggle Hide As Colleague On Shifts`,
  TOO_FAR_AWAY_PANEL: `${workerMobilePrefix} Too Far Away Failure Message`,
  TOOK_TIMESHEET_PHOTO: `${workerMobilePrefix} Timesheet Photo Taken`,
  TRIP_TRACKING_CALLED_ON_APP_OPEN: `${workerMobilePrefix} Trip Tracking Called on App Open`,
  TRIP_TRACKING_ERROR: `${workerMobilePrefix} Trip Tracking Error`,
  TRIP_TRACKING_FAILED: `${workerMobilePrefix} Trip Tracking Failed`,
  TRIP_TRACKING_NOT_CALLED_ON_APP_OPEN: `${workerMobilePrefix} Trip Tracking not Called on App Open`,
  TRIP_TRACKING_NOT_CALLED: `${workerMobilePrefix} Trip Tracking Not Called`,
  TRIP_TRACKING_STARTED_ON_APP_OPEN: `${workerMobilePrefix} Trip Tracking Started on App Open`,
  TRIP_TRACKING_STARTED_ON_SILENT_PUSH: `${workerMobilePrefix} Trip Tracking Started on Silent Push`,
  TRIP_TRACKING_STARTED: `${workerMobilePrefix} Trip Tracking Started`,
  TWO_FACTOR_CODE_ACCEPTED: `${workerMobilePrefix} Two-Factor Code Accepted`,
  TWO_FACTOR_CODE_DENIED: `${workerMobilePrefix} Two-Factor Code Denied`,
  UPDATED_ADDRESS: `${workerMobilePrefix} Address Updated`,
  UPDATED_DISTANCE_PREFERENCE: `${workerMobilePrefix} Distance Preference Updated`,
  UPDATED_PROFILE_PHOTO: `${workerMobilePrefix} Profile Photo Updated`,
  UPLOAD_API_SUCCESS: `${workerMobilePrefix} API Upload Succeeded`,
  UPLOAD_CALIFORNIA_TIMESHEET_FAILED: `${workerMobilePrefix} California Timesheet Upload Failed`,
  UPLOAD_CALIFORNIA_TIMESHEET_STARTED: `${workerMobilePrefix} California Timesheet Upload Started`,
  UPLOAD_CALIFORNIA_TIMESHEET_SUCCESS: `${workerMobilePrefix} California Timesheet Upload Succeeded`,
  UPLOAD_CLOUDINARY_SUCCESS: `${workerMobilePrefix} Cloudinary Upload Succeeded`,
  UPLOAD_TIMESHEET_FAILED: `${workerMobilePrefix} Timesheet Upload Failed`,
  UPLOAD_TIMESHEET_STARTED: `${workerMobilePrefix} Timesheet Upload Started`,
  UPLOAD_TIMESHEET_SUCCESS: `${workerMobilePrefix} Timesheet Upload Succeeded`,
  URGENT_SHIFT_BOOKING_FAILED: `${workerMobilePrefix} Urgent Shift Booking Failed`,
  URGENT_SHIFTS_PAGE_LOADED: `${workerMobilePrefix} Urgent Shifts Page Loaded`,
  VIEW_ADD_FRIEND_MODAL: `${workerMobilePrefix} View Add friend modal`,
  VIEW_ADD_FRIEND: `${workerMobilePrefix} View Add friend`,
  VIEW_OPEN_SHIFT_MAP: `${workerMobilePrefix} Open Shift Map Viewed`,
  VIEWED_ACCOUNT: `${workerMobilePrefix} My Account Viewed`,
  VIEWED_ADDRESS: `${workerMobilePrefix} Address Viewed`,
  VIEWED_BOOK_SHIFTS: `${workerMobilePrefix} Book Shifts Viewed`,
  VIEWED_CANCELLATION_PAYOUT_PANEL: `Cancellation Payout Panel View`,
  VIEWED_CBH_MISSING_DOCS_POPUP: `${workerMobilePrefix} Missing Docs Alert - CBH Viewed`,
  VIEWED_FACILITY_IMPORTANT_INFORMATION: `${workerMobilePrefix} Faciliy Important Information Viewed`,
  VIEWED_CHECK_IN_INSTRUCTIONS: `${workerMobilePrefix} Check In Instructions Viewed`,
  VIEWED_CONFIRM_BOOK_POPUP: `${workerMobilePrefix} Shift Confirm Viewed`,
  VIEWED_CONFIRM_INSTANTBOOK_POPUP: `${workerMobilePrefix} Shift Confirm Viewed`,
  VIEWED_CONNECTION_ISSUE: `${workerMobilePrefix} Connection Issue Viewed`,
  VIEWED_CONTRACT_COMPONENT: `${workerMobilePrefix} Onboarding - Contract Component Viewed`,
  VIEWED_DISTANCE_PREFERENCE: `${workerMobilePrefix} Distance Preference Viewed`,
  VIEWED_EXTRA_TIME_PAY_ALERT: `${workerMobilePrefix} Extra Time Pay Modal Viewed`,
  VIEWED_EXTRA_TIME_PAY_PANEL: `${workerMobilePrefix} Extra Time Pay Panel Viewed`,
  VIEWED_FACILITY_PROFILE: `${workerMobilePrefix} Facility Profile Viewed`,
  VIEWED_FACILITY_RATINGS: `${workerMobilePrefix} Facility Ratings Viewed`,
  VIEWED_FACILITY_SHIFTS: `${workerMobilePrefix} Facility Shifts Viewed`,
  VIEWED_HELP: `${workerMobilePrefix} Help Viewed`,
  VIEWED_MY_SHIFTS: `${workerMobilePrefix} My Shifts Viewed`,
  VIEWED_ONGOING_SHIFTS: `${workerMobilePrefix} Ongoing Shifts Viewed`,
  VIEWED_OPEN_SHIFTS: `${workerMobilePrefix} Open Shifts Viewed`,
  VIEWED_PAYROLL: `${workerMobilePrefix} Payroll Viewed`,
  VIEWED_PROFILE: `${workerMobilePrefix} Profile Viewed`,
  VIEWED_REFER_A_CO_WORKER: `${workerMobilePrefix} Refer a Co-worker Viewed`,
  VIEWED_STRIPE_SET_UP: `${workerMobilePrefix} Stripe Setup Viewed`,
  VIEWED_TERMS: `${workerMobilePrefix} Terms and Agreements Viewed`,
  VIEWED_UNVERIFIED_SHIFTS: `${workerMobilePrefix} Unverified Shifts Viewed`,
  VIEWED_URGENT_SHIFTS: `${workerMobilePrefix} Urgent Shifts Viewed`,
  VIEWED_WELCOME_TO_INSTANTPAY: `${workerMobilePrefix} Welcome to InstantPay Modal Viewed`,
  WORKER_LICENSE_ACTIVATED: `Frontend: Worker License Activated`, // not using common prefix here because same event is triggered from admin app
  VIEWED_MANDATORY_UNPAID_BREAK_MODAL: `${workerMobilePrefix} Mandatory unpaid break modal viewed`,
  QUIZ_WORKER_ON_WORKPLACE_RULES_FEATURE_FLAG_EVALUATION_ERROR: `${workerMobilePrefix} Quiz workers on workplace rules feature flag evaluation error`,
  FACILITY_VIDEO_OPENED: `${workerMobilePrefix} Facility Video Opened`,
  FACILITY_VIDEO_STARTED: `${workerMobilePrefix} Facility Video Started`,
  FACILITY_VIDEO_ENDED: `${workerMobilePrefix} Facility Video Ended`,
  OPENED_SUPPORT_ARTICLE: `${workerMobilePrefix} Opened Support Article`,
  SELF_CANCEL_SHIFT_ERROR: `${workerMobilePrefix} Self Cancel Shift Error`,
  CONVERT_SHIFT_TO_NON_INSTANT_PAY_ERROR: `${workerMobilePrefix} Convert Shift To Non Instant Pay Error`,
  EXTRA_WORKED_TIME_REQUEST_FORM_OPENED: `${workerMobilePrefix} Extra worked time request form opened`,
  EXTRA_WORKED_TIME_REQUEST_FORM_SAVED: `${workerMobilePrefix} Extra worked time request form saved`,
  SOLVE_UNPAID_BREAKS_VERSION_ERROR: `${workerMobilePrefix} Solve Unpaid Breaks Version Error`,
  MESSAGES_ICON_CLICKED: `${workerMobilePrefix} Messages icon clicked`,
  CANCEL_SHIFT_BUTTON_CLICKED: `${workerMobilePrefix} Cancel Shift Button Clicked`,
  SHOW_TIMELINESS_DEDUCTION_WARNING: `${workerMobilePrefix} Show Timeliness Deduction Warning`,
  URGENT_SHIFTS_UPDATE_LOCATION_ERROR: `${workerMobilePrefix} Location Permission Update Error`,
  STEP_COUNTING_API_UNAVAILABLE: `${workerMobilePrefix} Step Counting API Unavailable`,
  PERMISSION_CHECK: `${workerMobilePrefix} Permission Check`,
  SHIFT_PEDOMETER_DATA: `${workerMobilePrefix} Shift Pedometer Data`,
  SHIFT_PEDOMETER_DATA_FAILURE: `${workerMobilePrefix} Shift Pedometer Data Failure`,
} as const;

export type UserEvent = (typeof USER_EVENTS)[keyof typeof USER_EVENTS];

const brazeEventPrefix = "Braze:";

export const BRAZE_CUSTOM_EVENTS = {
  CLOCKED_IN: `${brazeEventPrefix} Clocked In`,
  TOO_FAR_AWAY_PANEL: `${brazeEventPrefix} Too Far Away Failure Message`,
};

export const SHIFT_BOOKED_CONTEXT = {
  HCF_MAP: "HCF Profile - Map",
  HCF_CALENDAR: "HCF Profile - Calendar",
  CALENDAR_DATE: "Calendar Date",
  URGENT_SHIFTS: "Urgent Shifts",
  REPLACEMENT: "Replacement",
  NEGOTIATING_SHIFTS: "Negotiating Shifts",
  NON_QFA_RANGE_SHIFTS: "Non Qfa Range Shifts from map view",
  WORKER_AVAILABILITY: "Worker Availability Shifts",
};

export const EXTERNAL_SHIFTS_EVENTS = {
  EXTERNAL_SHIFTS_ADDED: `${workerMobilePrefix} Finish adding external shifts`,
  EXTERNAL_SHIFTS_OPENED: `${workerMobilePrefix} Open external shift facility search`,
};

export const clockInBeforeOneHourError = "Clock-in cannot exceed one hour before posted start time";
export const earlyClockInIsDisabledError =
  "This shift does not pay for work before shift’s start time";
export const clockInAfterShiftEndError = "Clock-in cannot be after shift end time";
export const clockOutAfterTwoHoursError = "Clock-out cannot exceed two hours after posted end time";
export const clockOutPostActualClockOut =
  "Clock-out cannot be later than %s when you tapped 'Clock out'";
export const clockOutBeforeShiftStart = "Clock-out cannot be before shift start time";
export const breakEndBeforeBreakStart = "End break cannot be before start break";
export const clockOutBeforeClockIn = "Clock-in cannot be after clock-out";
export const eventTimeNotInOrder = "Event times must be in order";
export const clockInEmpty = "Enter clock-in time";
export const clockOutEmpty = "Enter clock-out time";
export const clockInOutEmpty = "Enter clock-in and clock-out times";
export const breakEndEmpty = "Enter break time";
export const clockInRadarError =
  "You cannot edit your clock-in time to before we recorded you entering the workplace at %s using location-tracking.";
export const breakStartRadarError =
  "You cannot edit your break-start time to after we recorded you left the workplace at %s using location-tracking.";
export const breakEndRadarError =
  "You cannot edit your break-end time to before we recorded you re-entering the workplace at %s using location-tracking.";
